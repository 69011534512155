document.addEventListener('DOMContentLoaded', () => {
	//----- global navigator -----//
	const navigatorButton = document.getElementById('global-navigator-toggle-button')
	const navigator = document.getElementById('global-navigator')
	navigatorButton?.addEventListener('click', e => {
		if(navigatorButton?.classList.contains('lp')){
			history.back()
			return
		}
		navigator?.classList.toggle('open')
		if(!document.body.style.hasOwnProperty('webkitBackdropFilter')){
			document.body.classList.toggle('navigator-open')
		}
	})

	//----- scroll top button -----//
	const scrollToTopButton = document.getElementById('scroll-to-top-button')
	scrollToTopButton?.addEventListener('click', () => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
	})

})

window.addEventListener('scroll', () => {
	if(window.scrollY){
		document.documentElement.classList.remove('is-top')
	} else {
		document.documentElement.classList.add('is-top')
	}
})